<template functional>
  <div class="not-found-container">Siden finnes ikke</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  width: 80%;
  max-width: 560px;
  margin: 0 auto;
  height: 100%;
  font-weight: 700;
  font-size: 10vmin;
  white-space: nowrap;
  color: $color-gray-400;
}
</style>
